












import {Vue, Component} from 'vue-property-decorator';
import LayoutWithControls from "@/layouts/LayoutWithControls.vue";
import Title from "@/components/Title.vue";

@Component({
  components: {LayoutWithControls, Title}
})
export default class Index extends Vue {
  async created() {
    this.$store.commit('session/dataReset');
    const user = await this.$auth.getUser();
    const token = this.$auth.getAccessToken();
    this.$store.commit('session/dataSet', {user, token});
    await this.$store.dispatch('applications/fetch');
  }

  get ready(): boolean {
    return [
      this.$store.getters['session/dataReady'],
      this.$store.getters['applications/dataReady'],
      this.$store.getters['configuration/dataReady'],
      this.$store.getters["versions/dataReady"],
    ].every(condition => !!condition);
  }
}
