import {DtoOktaConfigurationAbstraction} from "./dto.okta.configuration.abstraction";
import {get} from 'lodash';
import store from '@/store';
import {IsString} from "class-validator";

export class DtoOktaConfigurationSaveRequest extends DtoOktaConfigurationAbstraction {

  audiences;

  features;

  @IsString()
  version: string;

  async $send() {
    const payload = get(store, 'state.configuration.edited');
    const params = null;
    return await this.$http('post', 'configurations', { payload, params });
  }
}
