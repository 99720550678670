


















import {get} from 'lodash';
import {Vue, Component} from 'vue-property-decorator';
import Title from './Title.vue';

@Component({
  components: {Title}
})
export default class Header extends Vue {
  async logout() {
    this.$store.commit('session/dataReset');
    await this.$auth.signOut();
  }

  get user() {
    return get(this, '$store.state.session.user', {});
  }

  get version() {
    return this.$store.getters["configuration/version"];
  }

  get deployed() {
    return this.$store.getters["versions/deployed"]?.version;
  }

  get deployedMessage() {
    let message = `${this.deployed} deployed`;
    const latest = this.$store.getters["versions/latest"];
    message += (this.deployed == latest) ? ' (latest)' : ` (${latest} latest)`;
    return message;
  }
}
