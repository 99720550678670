



















import { Vue, Component } from 'vue-property-decorator';
import routerName from "../router/names";

@Component
export default class Menu extends Vue {
  navigations = [
    { text: "Applications", name: routerName.Applications },
    { text: "Configuration Versions", name: routerName.Versions },
    { text: "Features", name: routerName.Features },
    { text: "Audiences", name: routerName.Audiences },
    { text: "Rule Evaluators", name: routerName.RuleEvaluators },
  ];
}
