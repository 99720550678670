




























import {Vue, Component, Watch} from 'vue-property-decorator';
import Header from "../components/Header.vue";
import Menu from "../components/Menu.vue";
import ContentBox from "../components/ContentBox.vue";
import {DtoOktaConfigurationSaveRequest} from "@/classes/dtos/okta/configuration/dto.okta.configuration.save.request";

@Component({
  components: { Header, Menu, ContentBox }
})
export default class LayoutWithControls extends Vue {

  request = new DtoOktaConfigurationSaveRequest();

  toast;

  get hasChanged() {
    return this.$store.getters['configuration/isChanged'];
  }

  async save() {
    if (this.toast) this.toast.dismiss();
    this.request = await this.request.$send();
    if (this.request.$error)
      return this.toast = this.$toast.open({
        type: 'error',
        message: this.request.$error.response.data.error_message,
        duration: 0
      });
    this.$store.commit("versions/wipe");
    await this.$store.dispatch('configuration/fetchSpecific', this.request.version);
    await this.$store.dispatch('versions/updateVersions');
    this.$toast.success(`VERSION ${this.request.version} CREATED`);
  }

  @Watch('$route')
  onRouteChange() {
    if (this.toast) this.toast.dismiss();
  }
}
